<template>
  <div>
    <CRow>
      <CCol sm="12">
        <DirectionsTable
          :items="directions"
          hover
          striped
          border
          small
          fixed
          caption="Direcciones"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DirectionsTable from '../../components/directions/DirectionsTable.vue'
import directions from '../../services/directions';
import store from '../../store'

export default {
  name: 'Directions',
  components: { DirectionsTable },
  data: function () {
		return {
            directions: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await directions.get();

    if(response.type == "success"){
      this.directions = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.directions = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
