<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="directorModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CSelect
                  label="Colaborador: "
                  :value.sync="$v.form.user_id.$model"
                  :options="usersOptions"
                  invalidFeedback="Este es un campo obligatorio."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../services/collaborators';

export default {
  name: 'DirectorModal',
  props: {
    permissions: Array
  },
  data () {
    return {
      directorModal: false,
      title: "Asignar Responsable",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      users: [],
      usersOptions: []
    }
  },
  mounted: async function() {
    let response = await ws.get(); 

    if(response.type == "success"){
      this.users = response.data;
    }

    this.usersOptions = await this.$parseSelectOptionsOrdered(this.users, "id", "name", [{"label": "","value":""}]);
  },
  methods: {
    storeModal (id) {
      this.directorModal = true;
      this.color = "info";
      this.title = 'Asignar Responsable';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: id,
          user_id: ""
      }
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("store", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.directorModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          user_id: ""
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      user_id: {
        required
      }
    }
  },
}
</script>