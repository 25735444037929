<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()">Nueva Dirección</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #name="{item}">
          <td class="text-left w300">
            <div :style="'float: right; background-color: '+item.color+'; padding: 10px; width: 30px; height: 30px;'"></div>
            {{item.name}}
          </td>
        </template>
        <template #is_active="{item}"><td class="text-center w100">
          <CButton v-if="item.is_active" color="success" size="sm" @click="deactive(item)"><CIcon name="cil-check"/></CButton>
          <CButton v-if="!item.is_active" color="danger" size="sm" @click="active(item)"><CIcon name="cil-x-circle"/></CButton>
        </td></template>
        <template #phone="{item}"><td class="text-center w200">{{item.phone ? item.phone : '-'}}</td></template>
        <template #director_name="{item}"><td class="text-center">
        <div v-if="item.director_name">
          {{item.director_name}}
          <br />
          <span style="font-size: 12px; font-weight: bold;">
            {{item.job_position}}
          </span>
          <br />
          <CButton v-if="item.director_name" color="danger" size="sm" @click="clearDirector(item)" style="margin-top: 10px;">Remover</CButton>
        </div>
        <span v-else><CButton v-if="!item.director_name" color="info" size="sm" @click="directorModal(item)">Asignar</CButton></span>
        </td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <DirectionModal ref="directionModal" @store="storeProcess" @update="updateProcess"></DirectionModal>
    <DirectorModal ref="directorModal" @store="storeDirectorProcess"></DirectorModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import DirectionModal from './DirectionModal.vue'
import DirectorModal from './DirectorModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/directions';
import store from '../../store'

export default {
  name: 'DirectionsTable',
  components: { DirectionModal, DirectorModal, DeleteModal },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'name', label: 'Nombre'},
          {key: 'director_name', label: 'Responsable'},
          {key: 'phone', label: 'Whatsapp'},
          {key: 'is_active', label: 'Visible'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    storeModal () {
      this.$refs.directionModal.storeModal();
    },
    updateModal (item) {
      this.$refs.directionModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Dirección", item.id, item.name);
    },
    directorModal (item) {
      this.$refs.directorModal.storeModal(item.id);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async storeDirectorProcess(data) {
      let response = await ws.storeDirector(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async active(item) {
      let response = await ws.active({id: item.id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deactive(item) {
      let response = await ws.deactive({id: item.id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async clearDirector(item) {
      let response = await ws.clearDirector({id: item.id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
